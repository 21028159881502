<template>
  <div class="swiper__container hero__swiper--container" v-if="sliderLoad">
    <div class="swiper mySwiper hero__swiper">
      <div class="list-reset swiper-wrapper">
      <swiper :modules="modules" :pagination="true">
        <template v-for="slide in slides">
          <swiper-slide>
            <router-link :to="slide.url" class="swiper-slide hero__slide">
            <img :src="slide.background" />
            </router-link>
          </swiper-slide>
        </template>
      </swiper>
      </div>
    </div>
  </div>
</template>
<script>
import {ref, onUpdated, watch, onMounted} from 'vue'
import {useRouter} from "vue-router";

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';

export default {
  props: [

  ],
  data: () => ({
    modules: [Pagination],
    sliderLoad: false,
    slides: false
  }),
  computed: {

  },
  async created() {
    this.slides = await this.slider();
    this.sliderLoad = true;
  },
  methods: {
    async slider() {
     // if (this.$store.getters.slider === false)
        await this.$store.dispatch('fetchSlider')
      return this.$store.getters.slider;
    }
  },
  components: {
    Swiper,
    SwiperSlide
  }
}
</script>