import axios from "axios";
export default {
    state: {
        page: [],
        menu: false
    },
    mutations: {
        page(state, page) {
            state.page[page.id] = page.text;
        },
        menu(state, menu) {
            state.menu = menu.items;
        }
    },
    getters: {
        page: (state) =>  {
            return state.page;
        },
        menu: (state) =>  {
            return state.menu;
        },
    },
    actions: {
        async fetchPage({dispatch, commit}, data) {
            let response = await axios('/local/api/page/'+data.id).then(function (response) {
                let page = response.data; // читать тело ответа в формате JSON
                commit('page', {id: data.id, text:page.item});
            }); // завершается с заголовками ответа
        },
        async fetchMenu({dispatch, commit}) {
            let response = await axios('/local/api/menu').then(function (response) {
                let menu = response.data; // читать тело ответа в формате JSON
                commit('menu', menu);
            }); // завершается с заголовками ответа
        }
    }
}
