<template>
  <div v-show="success" class="info-block info-block--success">
    <p>{{ success }}</p>
  </div>
  <div v-show="error" class="info-block info-block--alert">
    <h3>Внимание</h3>
    <p v-for="item in error">{{item}}</p>
  </div>
  <form v-if="user" @submit.prevent="save" method="POST"
        class="registration__form personal-form personal-active--form">
    <label class="personal-label">
      <span class="personal__form--descr">Ваше имя</span>
      <input v-model="fio" type="text" placeholder="Как вас зовут?" class="personal-input personal-active--input">
    </label>
    <label class="personal-label">
      <span class="personal__form--descr">Дата рождения <span>*</span></span>
      <input v-model="birthday" required type="text" class="personal-input personal-active--input input-date" placeholder="__.__.____">
    </label>
    <label class="personal-label">
      <span class="personal__form--descr">Телефон <span>*</span></span>
      <input v-model="phone" required type="tel" class="personal-input personal-active--input input-tel" placeholder="+7 (___) ___-__-__">
    </label>
    <label class="personal-active--label personal-label">
      <span class="personal__form--descr">E-mail <span>*</span></span>
      <input v-model="email" required type="email" placeholder="test@mail.ru" class="personal-input personal-active--input">
    </label>
    <button type="submit" class="btn-reset personal__form--btn">Сохранить изменения</button>
  </form>
</template>

<script>
import axios from "axios";
import InputMask from 'primevue/inputmask';

export default {
  data: () => ({
    success: false,
    edit: false,
    email: '',
    phone: '',
    fio: '',
    birthday: '',
    error: false,
   }),
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    user() {
      let userInfo = this.$store.getters.user;
    if (userInfo) {
      this.birthday = userInfo.PERSONAL_BIRTHDAY
      this.email = userInfo.EMAIL;
      this.phone = userInfo.PERSONAL_PHONE;
      this.fio = userInfo.NAME;
    }
      return this.$store.getters.user;
    }
  },
  methods: {
    save() {
      this.error = false;
      this.success = false;

      let formData = new FormData();
      formData.append('email', this.email);
      formData.append('phone', this.phone);
      formData.append('fio', this.fio);
      formData.append('birthday', this.birthday);
      let _this = this;
      axios.post( '/local/api/personal/profile/save',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function(res){
        console.log(res)
        console.log('SUCCESS!!');
        if (res.data.error) {
          _this.error = res.data.error;
          $(window).scrollTop($(".info-block--alert").offset().top);
        }
        if (res.data.status == 200) {
          _this.success = res.data.text;
          _this.$store.dispatch('User', {
            PERSONAL_BIRTHDAY: _this.birthday,
            EMAIL: _this.email,
            PERSONAL_PHONE: _this.phone,
            NAME: _this.fio
          })
          $(window).scrollTop($(".info-block--success").offset().top);
        }
      })
          .catch(function(){
            console.log('FAILURE!!');
          });
    }
  },
  created() {

  },
  components: {InputMask}
}
</script>

