<template>
  <ul v-if="products" class="list-reset cards-grid bought">

    <li v-for="product in products" class="card card-grid">
      <ProductItem :item="product"></ProductItem>

    </li>
  </ul>
</template>
<script>

import ProductItem from "@/components/app/ProductItem";
export default {
  props: {
    id: '',
    sort: '',
    search: ''
  },
  data: () => ({

  }),
  computed: {
    products(){
      if (typeof this.$store.getters.products(this.$props.id) == 'undefined')
        return {};
      return this.$store.getters.products(this.$props.id)['items'];
    }
  },
  methods: {
    declOfNum(n, text_forms) {
      n = Math.abs(n) % 100;
      let n1 = n % 10;
      if (n > 10 && n < 20) { return text_forms[2]; }
      if (n1 > 1 && n1 < 5) { return text_forms[1]; }
      if (n1 == 1) { return text_forms[0]; }
      return text_forms[2];
    }
  },
  components: {
    ProductItem

  }
}
</script>