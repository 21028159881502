<template>
  <template v-if="page">
    <div v-if="page.detail" v-html="page.detail" class="section__wrapper"></div>
    <section v-else class="first__section">
      <div class="container">
        <div v-html="page.text"></div>
      </div>
    </section>
  </template>
</template>

<script>
import {ref, onUpdated, watch} from 'vue'
import { computed } from 'vue'
import {useGetToken} from "@/use/getToken";
import {useRouter} from "vue-router";

export default {
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    page() {
      return this.$store.getters.page[this.$route.params.id];
    }
  },
  methods: {
    async showPage() {
      await this.$store.dispatch({type:'fetchPage', id:this.$route.params.id})
    }
  },
  created() {
    this.showPage();
  },
}
</script>

