import Catalog from "./views/Catalog.vue";
import Main from './views/Main.vue'
import MainNotAuth from './views/MainNotAuth.vue'
import Login from './views/Login.vue'
import Notfound from './views/Notfound.vue'
import Registration from './views/Registration.vue'
import RegistrationComplete from './views/RegistrationComplete.vue'
import ForgetPassword from './views/ForgetPassword.vue'
import NewPassword from './views/NewPassword.vue'
import About from './views/About.vue'
import Personal from './views/Personal.vue'
import PersonalNotAuth from './views/PersonalNotAuth.vue'
import Profile from './views/Profile.vue'
import Orders from './views/Orders.vue'
import ChangePassword from './views/ChangePassword.vue'
import Section from './views/Section.vue'
import Sale from './views/Sale.vue'
import New from './views/New.vue'
import Detail from './views/Detail.vue'
import Basket from './views/Basket.vue'
import Order from './views/Order.vue'
import OrderComplete from './views/OrderComplete.vue'
import OrderDetail from './views/OrderDetail.vue'
import Shop from './views/Shop.vue'
import Page from './views/Page.vue'
import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import store from './store'
import OrderSuccess from './views/OrderSuccess.vue'

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuth) {
        next()
        return
    }
    next('/')
}

const ifAuthenticated = (to, from, next) => {
    if (from.name == undefined) {
        next();
        return;
    }
    if (store.getters.isAuth) {
        next()
        return
    }
    next('/personal-not-auth')
}

export default new createRouter({
    mode: 'history',
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
    //base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            component: Login,
            name:'login',
            meta: {layout: 'page', title: 'Авторизация'},
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/registration',
            component: Registration,
            name:'registration',
            meta: {layout: 'page', title: 'Регистрация'},
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/registration-complete',
            component: RegistrationComplete,
            name:'registration-complete',
            meta: {layout: 'page', title: 'Заявка принята'},
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/ordersuccess/',
            component: OrderSuccess,
            name:'order-success',
            meta: {layout: 'page', title: 'Заказ принят'} ,
        },
        {
            path: '/forget-password',
            component: ForgetPassword,
            name:'forget-password',
            meta: {layout: 'page', title: 'Восстановление пароля'},
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/new-password',
            component: NewPassword,
            name:'new-password',
            meta: {layout: 'page', title: 'Новый пароль'},
            beforeEnter: ifNotAuthenticated
        },
        {
            path: '/about',
            component: About,
            name:'about',
            meta: {layout: 'page', title: 'Информация о компании'},
        },
        {
            path: '/catalog',
            component: Catalog,
            name:'catalog',
            meta: {layout: 'page', title: 'Каталог', prevpage: '/', bigTitle: true} ,
        },
        {
            path: '/catalog/:id',
            component: Section,
            name:'section',
            meta: { layout: 'page', title: 'Каталог'} ,
        },
        {
            path: '/detail/:id',
            component: Detail,
            name:'detail',
            meta: {layout: 'product', title: 'Каталог'} ,
        },
        {
            path: '/basket',
            component: Basket,
            name:'basket',
            meta: {layout: 'page', title: 'Корзина'} ,
        },
        {
            path: '/order-complete/:id',
            component: OrderComplete,
            name:'order-complete',
            meta: {layout: 'page', title: 'Заказ принят'} ,
        },
        {
            path: '/order',
            component: Order,
            name:'order',
            meta: {layout: 'page', title: 'Оформление заказа'} ,
        },
        {
            path: '/',
            component: Main,
            name:'main',
            meta: {layout: 'main', showLogo: true},
        },
        {
            path: '/personal',
            component: Personal,
            name:'personal',
            meta: {layout: 'page', title: 'Личный кабинет', prevpage: '/'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/personal-not-auth',
            component: PersonalNotAuth,
            name:'personal-not-auth',
            meta: {layout: 'page', title: 'Личный кабинет'},
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/orderdetail/:id',
            component: OrderDetail,
            name:'order_detail',
            meta: {layout: 'page', title: 'Заказ'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/orders',
            component: Orders,
            name:'orders',
            meta: {layout: 'page', title: 'Мои заказы'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/change-password',
            component: ChangePassword,
            name:'change-password',
            meta: {layout: 'page', title: 'Изменить пароль'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/shop',
            component: Shop,
            name:'shop',

            meta: {layout: 'page', title: 'Магазины', prevpage: '/'}
        },
        {
            path: '/page/:id',
            component: Page,
            name:'page',
            meta: {layout: 'page', title: 'Страница'}
        },
        {
            path: '/:pathMatch(.*)*',
            component: Notfound,
            name:'notfound',
            meta: {layout: 'page', title: 'Ошибка'}
        }
    ]
})