<template>
  <section class="section">
    <div class="container">
      <div class="section__wrapper">
        <router-link to="/" href="#" class="personal__logo">
          <img src="img/personal-logo.png" alt="Логотип ХМЕЛЬ и ХОЛОДЪ">
        </router-link>
        <div class="info-block info-block--alert" v-if="error">
          <h3>Внимание</h3>
          <p><span v-html="error"></span></p>
        </div>
        <form action="" @submit.prevent="auth"
              class="registration__form personal-form">
          <label>
            <span class="personal__form--descr">E-mail <span>*</span></span>
            <input type="email" required v-model="login" autocomplete="off" placeholder="test@mail.ru" class="personal-input">
          </label>
          <label>
            <span class="personal__form--descr">Пароль <span>*</span></span>
            <input type="password" required v-model="password" placeholder="Введите данные" class="personal-input">
          </label>
          <button type="submit" class="btn-reset personal__form--btn">Войти</button>
        </form>
        <router-link to="/registration" href="#" class="authorization-link registr">Регистрация</router-link>
        <router-link to="/forget-password" href="#" class="authorization-link forgot-password">Забыли пароль?</router-link>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  props: [

  ],
  data: () => ({
    login: '',
    password: '',
    error: ''
  }),
  created() {

  },
  mounted() {

    this.$store.dispatch('Loader');
  },
  beforeRouteLeave(to, from) {

    // called when the route that renders this component is about to be navigated away from.
    // As with `beforeRouteUpdate`, it has access to `this` component instance.
  },
  methods: {
    auth() {
      this.error = '';
      let device_uuid = this.$store.getters.uuid;
      let _this = this;
      axios.post('/local/api/login', {

          login: this.login,
          password: this.password,

      }).then(response => {
        let data = response.data;
        if (data.is_auth === true) {
          _this.$store.dispatch('Auth', {
            login: data.login,
            token: data.token,
            user: data.arUser,
            uuid: data.uuid
          })
          _this.axios.defaults.params = {Authorization: data.token}
          _this.$router.push('/');
        }
        else {
          _this.error = data.error.MESSAGE;
          console.log(data)
        }
      });

    }
  },
  components: {

  }
}
</script>

