<template>
    <main class="main" :class="{'with_basket':basket_quantity>0}">
          <router-view />
    </main>
    <FlyBasket v-if="!hideBasket"></FlyBasket>
    <Footer></Footer>
</template>

<script>
import Footer from '../components/app/Footer'
import FlyBasket from '../components/app/FlyBasket'
export default {
  name: 'product-layout',
  data: () => ({

  }),
  computed: {
    login() {
      return this.$store.getters.login;
    },
    name() {
      if (typeof this.$store.getters.user == 'object')
        return this.$store.getters.user.UF_NAME;
    },
    isAuth() {
      return this.$store.getters.isAuth;
    },
    basket_quantity() {
      return this.$store.getters.flyBasket.quantity;
    }
  },
  components: {
  Footer,
    FlyBasket
  }
}
</script>
