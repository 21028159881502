<template>
  <router-link :to="item.url" class="order__link">
    <div class="order__img">
      <img v-if="item.picture" :src="item.picture">
    </div>
    <div class="order__product">
      <h4 class="order__name">{{ item.name }}</h4>
      <div class="order__amount flex">
        <div class="count">
          <span class="volume">{{ item.ratio }} {{ item.measure }}</span>
        </div>
      </div>
    </div>
  </router-link>
  <div class="cart__counts flex">
    <button @click="deleteItem" class="btn-reset cart__cancel"><svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M6.71967 6.71967C7.01256 6.42678 7.48744 6.42678 7.78033 6.71967L10 8.93934L12.2197 6.71967C12.5126 6.42678 12.9874 6.42678 13.2803 6.71967C13.5732 7.01256 13.5732 7.48744 13.2803 7.78033L11.0607 10L13.2803 12.2197C13.5732 12.5126 13.5732 12.9874 13.2803 13.2803C12.9874 13.5732 12.5126 13.5732 12.2197 13.2803L10 11.0607L7.78033 13.2803C7.48744 13.5732 7.01256 13.5732 6.71967 13.2803C6.42678 12.9874 6.42678 12.5126 6.71967 12.2197L8.93934 10L6.71967 7.78033C6.42678 7.48744 6.42678 7.01256 6.71967 6.71967Z"
            fill="black" />
    </svg>
    </button>
    <button @click="changeQuantity('minus')" class="btn-reset product__count--button__minus" data-counter="minus">
    </button>
    <div class="count">
      <input class="quantity" type="number" v-model="quantity" disabled>
      <span class="volume">{{ item.measure }}</span>
    </div>
    <span class="border"></span>
    <div class="summ">
      <span class="cost">{{ item.price * quantity }}</span>
      <span class="currency">₽</span>
    </div>
    <button @click="changeQuantity('plus')" class="btn-reset product__count--button__plus" data-counter="plus"></button>
  </div>
</template>
<script>

export default {
  props: {
    item: {},
    itembasket: false,
    itemorder: false
  },
  data: () => ({
    quantity: 0
  }),
  computed: {
    basket() {
      let basket_quantity = this.$store.getters.inBasket(this.$props.item.product_id);
      if (basket_quantity != this.quantity)
        this.quantity = basket_quantity;
      return basket_quantity;
    }
  },
  watch: {
    basket(val) {
      if (val != this.quantity)
        this.quantity = val;
    }
  },
  updated() {

  },
  created() {
   // this.quantity = this.$props.item.quantity;
  },
  methods: {
    changeQuantity(type) {
      let act = '';
      let quantity = this.quantity;
      if (type == 'plus') {
        /*if (this.$props.item.quantity <= this.basket)
          return;*/
        if (quantity == 0)
          act = 'add';
        else
          act = 'update';
        quantity += this.$props.item.ratio;
      }
      else {
        if (quantity > this.$props.item.ratio) {
          act = 'update';
        }
        else if (quantity == this.$props.item.ratio) {
          act = 'delete';
        }
        if (act)
          quantity -= this.$props.item.ratio;
      }
      if (act == 'add')
        this.$store.dispatch({type:'addBasket', id: this.$props.item.product_id, quantity: quantity})
      else if (act == 'update')
        this.$store.dispatch({type:'updateBasket', id: this.$props.item.product_id, quantity: quantity})
      else if (act == 'delete')
        this.$store.dispatch({type:'deleteBasket', id: this.$props.item.product_id, quantity: quantity})
    },
    async deleteItem() {
      await this.$store.dispatch({type:'deleteBasket', id: this.$props.item.product_id, quantity: 0})
    }
  },
  components: {

  }
}
</script>