<template>
  <section class="section">
    <div class="container">

      <ul class="list-reset addresses__nav">
        <li @click="tab='map'" class="addresses__nav--item">
          <button class="btn-reset addressess__nav--btn" :class="{' addressess__nav--btn__active':tab=='map'}" data-path="map">Карта</button>
        </li>
        <li @click="tab='list'" class="addresses__nav--item">
          <button class="btn-reset addressess__nav--btn" :class="{' addressess__nav--btn__active':tab!='map'}" data-path="addresses">Список
            адресов</button>
        </li>
      </ul>

      <div class="addresses__content">
        <div class="addresses__map addresses__item"
             :class="{' addresses__item--active':tab=='map'}"
             style="width: 100%; height: 575px;" data-target="map">
          <YandexMap :settings="settings" :coordinates="[59.94198153309988,30.345354574098717]" style="width: 100%; height: 575px;">
            <YandexClusterer :options="optioncluster">
            <YandexMarker v-for="item in shop"
                :coordinates="item.coordinate"
                :marker-id="item.ID"
                          :options="options"
            >
              <template #component>
                <div>
                {{ item.metro }}<br>
                {{ item.name }}<br>
                  <span v-html="item.work"></span>
                </div>
              </template>
            </YandexMarker>
            </YandexClusterer>
          </YandexMap>
        </div>
        <ul class="list-reset addresses__list addresses__item"
            :class="{' addresses__item--active':tab!='map'}"
            data-target="addresses">
          <li v-for="item in shop" class="addresses__block">
            <h2 class="addresses__title h2-title">{{ item.metro }}</h2>
            <address>{{ item.name }}</address>
            <div class="addresses__inner">
              <div class="addresses__schedule">
                <span v-html="item.work"></span>
              </div>
              <a href="tel:+79219506504" class="addresses__tel">{{item.phone}}</a>
            </div>
            <a v-if="item.yandex" target="_blank" :href="item.yandex" class="addresses__more">Смотреть на Яндекс.Картах</a>
          </li>
        </ul>
      </div>

    </div>
  </section>
</template>

<script>
import {yandexMap} from 'vue-yandex-maps'
import InputMask from 'primevue/inputmask';

//import { yandexMap, ymapMarker } from 'vue-yandex-maps'
export default {
  data: () => ({
    tab: 'map',
    settings: {
      apiKey: '', // Индивидуальный ключ API
      lang: 'ru_RU', // Используемый язык
      coordorder: 'latlong', // Порядок задания географических координат
      debug: false, // Режим отладки
      version: '2.1' // Версия Я.Карт
    },
    marker: [
      {
        coordinates: [59.932545,30.346725],
        text: 'Невский',
        id: 1
      },
      {
        coordinates: [60.0430897884379,30.33256580151572],
        text: 'Энгельса',
        id: 2
      }
    ],
    options: {
      iconLayout: 'default#image',
      iconImageHref: "/img/map-target.svg",
      iconImageSize: [47, 74],
      iconImageOffset: [-47, -74]
    },
    optioncluster: {
      preset: 'islands#nightClusterIcons',
      iconColor: '#FBCD0B',
      options: {
        iconColor: '#FBCD0B',
        icons: {
          href: "/img/map-more.png",
          size: [47, 74],
          offset: [-47, -74]
        }
      }
    }
  }),
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    shop() {
      return this.$store.getters.shop;
    }
  },
  methods: {
    async showShop() {
      await this.$store.dispatch('fetchShop')
    },
  },
  created() {
    this.showShop();
  },
  components: {
    yandexMap, InputMask
  }
}
</script>

