<template>
  <section class="section">

    <div class="container">
      <ul class="list-reset address__list">
        <template v-for="item in menu">
        <li class="address__item">
          <router-link :to="item.link" href="addresses.html" class="address__link">{{ item.text }}</router-link>
          <svg width="20" height="20"
                                                                                                viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M7.09467 4.71967C7.38756 4.42678 7.86244 4.42678 8.15533 4.71967L12.9053 9.46967C13.1982 9.76256 13.1982 10.2374 12.9053 10.5303L8.15533 15.2803C7.86244 15.5732 7.38756 15.5732 7.09467 15.2803C6.80178 14.9874 6.80178 14.5126 7.09467 14.2197L11.3143 10L7.09467 5.78033C6.80178 5.48744 6.80178 5.01256 7.09467 4.71967Z"
                fill="black" />
        </svg>
        </li>
        </template>
      </ul>
    </div>

  </section>
</template>

<script>
import {ref, onUpdated, watch} from 'vue'
import { computed } from 'vue'
import {useGetToken} from "@/use/getToken";
import {useRouter} from "vue-router";

export default {
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    menu() {
      return this.$store.getters.menu;
    }
  },
  methods: {
    async showMenu() {
      await this.$store.dispatch('fetchMenu')
    }
  },
  created() {
    this.showMenu();
  },
}
</script>

