import { createApp, defineAsyncComponent } from 'vue'
import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import router from './router'
import App from './App.vue'
import './style.css'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import YmapPlugin from 'vue-yandex-maps'


/*
const Registration = () => import('./Registration.vue')

const routes = [
    { path: '/auth', component: Auth, name:'auth' },
    { path: '/test', component: Test },
    { path: '/personal/', component: Registration, name:'registration' },
    { path: '/catalog/', component: Catalog, name:'catalog' },
    { path: '/', component: Main, name:'main', meta: {layout: 'main'}, }
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

router.beforeEach((to, from, next) => {

  // debugger;
    //if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
    //else
    next()
})
*/
let token = (new Date()).getTime();

if (localStorage.getItem('user-token'))
    token = localStorage.getItem('user-token');

if (token) {
    axios.defaults.headers.common['Authorization'] = token;
    axios.defaults.baseURL = window.location.protocol+'//'+window.location.host;
    if (process.env.NODE_ENV == 'development')
        axios.defaults.baseURL = 'https://hmel.alm.su';
}

document.addEventListener('deviceready', onDeviceReady, false);
function onDeviceReady() {
    let device_uuid = '';
    if (typeof device == 'object') {
        device_uuid = device.uuid;
        axios.defaults.headers.common['Authorization'] = device_uuid;
        axios.defaults.baseURL = window.location.protocol+'//'+window.location.host;

        if (device.platform.indexOf("iOS") != -1) {
            //document.body.classList.add('ios');
            if (device.model.indexOf("iPhone10") != -1
                || device.model.indexOf("iPhone11") != -1
                || device.model.indexOf("iPhone12") != -1
                || device.model.indexOf("iPhone13") != -1
                || device.model.indexOf("iPhone14") != -1
                || device.model.indexOf("iPhone15") != -1
                || device.model.indexOf("iPhone16") != -1
                || device.model.indexOf("iPhone17") != -1)
                document.body.classList.add('ios');
        }
    }
    const app2 = createApp(App, {device_uuid: device_uuid})
// Make sure to _use_ the router instance to make the
// whole app router-aware.
    app2.use(router)
    app2.use(store)
    app2.use(VueAxios, axios)
    const settings = {
        apiKey: '', // Индивидуальный ключ API
        lang: 'ru_RU', // Используемый язык
        coordorder: 'latlong', // Порядок задания географических координат
        debug: false, // Режим отладки
        version: '2.1' // Версия Я.Карт
    }
    app2.use(YmapPlugin, settings)
    app2.mount('#app')
}
if (process.env.NODE_ENV == 'development')
    onDeviceReady();
