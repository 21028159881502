<template>
  <div class="info-block info-block--alert" v-show="error.length>0">
    <h3>Внимание</h3>
    <p v-for="item in error">{{item}}</p>
  </div>
  <div v-show="success" class="info-block info-block--success">
    <p>{{ success }}</p>
  </div>
  <form @submit.prevent="changePassword" method="POST"
        class="registration__form personal-form personal-active--form">
    <label class="personal-label">
      <span class="personal__form--descr">Старый пароль</span>
      <input v-model="password_old" required type="password" placeholder="Введите данные" class="personal-input personal-active--input">
    </label>
    <label class="personal-label">
      <span class="personal__form--descr">Придумайте пароль <span>*</span></span>
      <input v-model="password" required type="password" placeholder="Не менее 8 символов"
             class="personal-input personal-active--input">
    </label>
    <label class="personal-label">
      <span class="personal__form--descr">Повторите пароль <span>*</span></span>
      <input v-model="password_confirm" required type="password" placeholder="Не менее 8 символов"
             class="personal-input personal-active--input">
    </label>
    <button type="submit" class="btn-reset personal__form--btn">Сохранить изменения</button>
  </form>
</template>

<script>
import axios from "axios";

export default {
  props: [
  ],
  data: () => ({
    error: [],
    password_old: '',
    password: '',
    password_confirm: '',
    success: false
  }),
  created() {

  },
  mounted() {
    this.$store.dispatch('Loader');
  },
  methods: {
    changePassword() {
      this.error = [];
      this.success = false;

      if (!this.password_old)
        this.error.push('Введите Старый пароль');
      if (!this.password)
        this.error.push('Введите пароль');
      if (!this.password_confirm)
        this.error.push('Введите подтверждение пароля');
      if (this.password != this.password_confirm)
        this.error.push('Введённые пароли не совпадают');
      if (this.error.length > 0) {
        $(window).scrollTop($(".info-block--alert").offset().top);
        return;
      }

      let formData = new FormData();
      formData.append('password_old', this.password_old);
      formData.append('password', this.password);
      formData.append('password_confirm', this.password_confirm);
      let _this = this;
      axios.post( '/local/api/password/change',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function(res){
        console.log(res)
        console.log('SUCCESS!!');
        if (res.data.error) {
          _this.error = res.data.error;
          $(window).scrollTop($(".info-block--alert").offset().top);
        }
        if (res.data.status == 200)
          _this.success = res.data.text;
        _this.password_old = '';
        _this.password = '';
        _this.password_confirm = '';
          $(window).scrollTop($(".info-block--success").offset().top);
      })
          .catch(function(){
            console.log('FAILURE!!');
          });


    },
  },
  components: {

  }
}
</script>

