<template>
  <div v-if="shop" class="container">
    <button @click="showPopup = !showPopup" class="btn-reset location__btn flex">
      <div class="location__wrapper flex">
        <svg class="location" width="20" height="20" viewBox="0 0 20 20" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_100_2695)">
            <path
                d="M10.0001 5C9.34081 5 8.69635 5.1955 8.14818 5.56177C7.60002 5.92804 7.17278 6.44863 6.92048 7.05772C6.66819 7.66681 6.60218 8.33703 6.7308 8.98363C6.85942 9.63024 7.17689 10.2242 7.64306 10.6904C8.10924 11.1565 8.70318 11.474 9.34978 11.6026C9.99639 11.7312 10.6666 11.6652 11.2757 11.4129C11.8848 11.1606 12.4054 10.7334 12.7716 10.1852C13.1379 9.63707 13.3334 8.9926 13.3334 8.33333C13.3334 7.44928 12.9822 6.60143 12.3571 5.97631C11.732 5.35119 10.8841 5 10.0001 5ZM10.0001 10C9.67045 10 9.34822 9.90225 9.07413 9.71912C8.80005 9.53598 8.58643 9.27568 8.46028 8.97114C8.33414 8.6666 8.30113 8.33148 8.36544 8.00818C8.42975 7.68488 8.58848 7.38791 8.82157 7.15482C9.05466 6.92173 9.35163 6.763 9.67493 6.69869C9.99823 6.63438 10.3333 6.66739 10.6379 6.79353C10.9424 6.91968 11.2027 7.1333 11.3859 7.40738C11.569 7.68147 11.6668 8.0037 11.6668 8.33333C11.6668 8.77536 11.4912 9.19928 11.1786 9.51184C10.866 9.82441 10.4421 10 10.0001 10Z"
                fill="#FBCD0B" />
            <path
                d="M10.0003 20.0002C9.29855 20.0038 8.60619 19.8392 7.98115 19.5202C7.35611 19.2013 6.81659 18.7372 6.40776 18.1669C3.23193 13.786 1.62109 10.4927 1.62109 8.3777C1.62109 6.15541 2.5039 4.02414 4.07529 2.45274C5.64669 0.881338 7.77797 -0.00146484 10.0003 -0.00146484C12.2226 -0.00146484 14.3538 0.881338 15.9252 2.45274C17.4966 4.02414 18.3794 6.15541 18.3794 8.3777C18.3794 10.4927 16.7686 13.786 13.5928 18.1669C13.1839 18.7372 12.6444 19.2013 12.0194 19.5202C11.3943 19.8392 10.702 20.0038 10.0003 20.0002ZM10.0003 1.8177C8.26061 1.81969 6.59277 2.51164 5.36265 3.74176C4.13253 4.97188 3.44058 6.63972 3.43859 8.37937C3.43859 10.0544 5.01609 13.1519 7.87943 17.101C8.12251 17.4359 8.4414 17.7084 8.81002 17.8963C9.17864 18.0842 9.58651 18.1821 10.0003 18.1821C10.414 18.1821 10.8219 18.0842 11.1905 17.8963C11.5591 17.7084 11.878 17.4359 12.1211 17.101C14.9844 13.1519 16.5619 10.0544 16.5619 8.37937C16.5599 6.63972 15.868 4.97188 14.6379 3.74176C13.4077 2.51164 11.7399 1.81969 10.0003 1.8177Z"
                fill="#FBCD0B" />
          </g>
          <defs>
            <clipPath id="clip0_100_2695">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <div v-if="current_shop" class="location__address flex">
          <span class="location__street">{{ current_shop.name }}</span>
          <span class="location__station">{{current_shop.metro}}</span>
        </div>
        <div v-else class="location__address flex">
          <span class="location__street">Выберите магазин</span>
        </div>
      </div>
      <svg class="arrow" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M7.09467 4.71967C7.38756 4.42678 7.86244 4.42678 8.15533 4.71967L12.9053 9.46967C13.1982 9.76256 13.1982 10.2374 12.9053 10.5303L8.15533 15.2803C7.86244 15.5732 7.38756 15.5732 7.09467 15.2803C6.80178 14.9874 6.80178 14.5126 7.09467 14.2197L11.3143 10L7.09467 5.78033C6.80178 5.48744 6.80178 5.01256 7.09467 4.71967Z"
              fill="#FBCD0B" />
      </svg>
    </button>
    <div
      class="popup__container"
      :class="showPopup || showClearBasket ? 'popup__container--open' : ''"
      @click="showPopup = false;"
    >
      <div v-if="showPopup" class="location__popup flex location__popup--active">
        <div class="location__card">
          <div class="location__text">
            <button @click="showPopup = false" class="btn-reset location__cancel">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M6.71967 6.71967C7.01256 6.42678 7.48744 6.42678 7.78033 6.71967L10 8.93934L12.2197 6.71967C12.5126 6.42678 12.9874 6.42678 13.2803 6.71967C13.5732 7.01256 13.5732 7.48744 13.2803 7.78033L11.0607 10L13.2803 12.2197C13.5732 12.5126 13.5732 12.9874 13.2803 13.2803C12.9874 13.5732 12.5126 13.5732 12.2197 13.2803L10 11.0607L7.78033 13.2803C7.48744 13.5732 7.01256 13.5732 6.71967 13.2803C6.42678 12.9874 6.42678 12.5126 6.71967 12.2197L8.93934 10L6.71967 7.78033C6.42678 7.48744 6.42678 7.01256 6.71967 6.71967Z"
                    fill="black" />
              </svg>
            </button>
            <h3 class="location__subtitle">Чтобы посмотреть каталог, выберите магазин</h3>
          </div>
          <form action="https://jsonplaceholder.typicode.com/posts" method="POST" class="location__form flex">
            <label v-for="item in shops" class="location__form--label">
              <input v-model="modelShop" @click="changeShop(item.id)" type="radio" class="location__form--input radio-btn"
                     :value="item.id">
              <span class="custom-check" :class="{'shop-checked':item.selected}"></span>
              <span class="location__form--street">{{item.name}}</span>
              <span class="location__form--station">{{item.metro}}</span>
            </label>
          </form>
        </div>
      </div>

      <div v-if="showClearBasket" class="change__location--popup change__location--popup__active">
        <div class="location__notice">
          <button class="btn-reset location__cancel" @click="resetShop">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M6.71967 6.71967C7.01256 6.42678 7.48744 6.42678 7.78033 6.71967L10 8.93934L12.2197 6.71967C12.5126 6.42678 12.9874 6.42678 13.2803 6.71967C13.5732 7.01256 13.5732 7.48744 13.2803 7.78033L11.0607 10L13.2803 12.2197C13.5732 12.5126 13.5732 12.9874 13.2803 13.2803C12.9874 13.5732 12.5126 13.5732 12.2197 13.2803L10 11.0607L7.78033 13.2803C7.48744 13.5732 7.01256 13.5732 6.71967 13.2803C6.42678 12.9874 6.42678 12.5126 6.71967 12.2197L8.93934 10L6.71967 7.78033C6.42678 7.48744 6.42678 7.01256 6.71967 6.71967Z"
                    fill="black" />
            </svg>
          </button>
          <h5 class="change__location--subtitle">Вы изменили адрес, корзина будет<br> очищена.</h5>
          <div class="change__location--btns">
            <button @click="resetShop" class="btn-reset change__location--btn__cancel">Отменить</button>
            <button @click="clearBasket" class="btn-reset change__location--btn__approve">Очистить корзину</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  props: [

  ],
  data: () => ({
    shops: [],
    showPopup: false,
    newid: false,
    showClearBasket: false,
    modelShop: false
  }),
  computed: {
    current_shop() {
      if (this.$store.getters.current_shop)
      this.modelShop = this.$store.getters.current_shop.id;
      return this.$store.getters.current_shop;
    }
  },
  async created() {
    this.shops = await this.shop();
  },
  methods: {
    async shop() {
     // if (this.$store.getters.homeSale === false || this.$store.getters.homeSale == undefined)
        await this.$store.dispatch('fetchShop')
      return this.$store.getters.shop;
    },
    async setshop(id) {
      this.$store.dispatch({type: 'setShop', id: id});
      if (this.$route.name == 'catalog')
        this.$store.dispatch({type: 'fetchSections', shop_id: id})
      else
        this.$store.dispatch({type:'fetchProducts', section_id:this.$route.params.id, shop_id: id});
      this.$store.dispatch('fetchFlyBasket')
    },
    clearBasket() {
      this.setshop(this.newid);
      this.newid = false;
      this.showClearBasket = false;
    },
    resetShop() {
      this.showClearBasket = false;
      this.modelShop = this.$store.getters.current_shop.id;
      this.newid = false;
      this.showClearBasket = false;
    },
    changeShop(id) {
      if (this.current_shop && id == this.current_shop.id)
        return;
      if (this.$store.getters.flyBasket.quantity > 0) {
        this.newid = id;
        this.showPopup = false;
        this.showClearBasket = true;
      }
      else {
        this.setshop(id);
        this.showPopup = false;
      }
    }
  },
  watch: {
    /*modelShop(id) {
      debugger;
      if (this.current_shop && id == this.current_shop.id)
        return;
      if (this.$store.getters.flyBasket.quantity > 0) {
        this.newid = id;
        this.showPopup = false;
        this.showClearBasket = true;
      }
      else {
        this.setshop(id);
        this.showPopup = false;
      }
    }*/
  },
  components: {

  }
}
</script>