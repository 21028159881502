import axios from 'axios'

export default {
    state: {
        about_header: '',
        about_text: '',
        about_image: '',
        phone: '',
        vk: ''
    },
    mutations: {
        about(state, data) {
            state.about_header = data.about.header;
            state.about_text = data.about.text;
            state.about_image = data.about.image;
            state.phone = data.about.phone;
            state.vk = data.about.vk;

        }
    },
    getters: {
        about_header: s => s.about_header,
        about_text: s => s.about_text,
        about_image: s => s.about_image,
        phone: s => s.phone,
        vk: s => s.vk,
    },
    actions: {
        fetchHomeAbout({dispatch, commit}) {
            let response = axios('/local/api/about').then(function (data) {
                commit('about', data.data);
            });
        }
    }
}
