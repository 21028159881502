<template>
    <component :is="layout" v-if="isLoad">
      <router-view/>
    </component>
    <div class="preloader preloader--loading" v-if="!loader"></div>
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout'
import MainLayout from '@/layouts/MainLayout'
import PageLayout from '@/layouts/PageLayout'
import ProductLayout from '@/layouts/ProductLayout'
import axios from 'axios'
export default {
  data: () => ({
    myvar: 'test',
    device_uuid: '',
    device_token: '',
    isLoad: false
  }),
  computed: {
    layout() {
      return (this.$route.meta.layout || 'empty') + '-layout'
    },
    loader() {
      return this.$store.getters.loader;
    },
    mytoken() {
      return this.$attrs.mytoken
    }
  },
  components: {
    EmptyLayout, MainLayout, PageLayout, ProductLayout
  },
  async created() {
    let device_uuid = this.$attrs.device_uuid;
    this.device_uuid = device_uuid;
    localStorage.setItem('user-token', device_uuid);
    this.$store.dispatch('Uuid', device_uuid)
    let result  = await axios.get('/local/api/auth').catch(function(){

    });
    if (typeof result == 'object' && typeof result.data == 'object') {
      let auth = result.data;
      if (auth.is_auth === true) {
        this.$store.dispatch('Auth', {
          login: auth.arUser.LOGIN,
          user: auth.arUser,
          token: auth.token,
          uuid: auth.uuid
        })
      }
    }
    //console.log(result)
    let _this = this;
    if (typeof window.FirebasePlugin != 'undefined')
      window.FirebasePlugin.getToken(async function (device_token) {
        let response = await fetch('/local/ajax/token.php?token='+device_token+'&uuid='+_this.device_uuid); // завершается с заголовками ответа
        let result = await response.json(); // читать тело ответа в формате JSON
      }, function (error) {
        //alert('error')
      });
    /*if (this.$store.getters.isAuth === false)
      this.$router.push('/main-not-auth');*/
    this.isLoad = true;
  }
}
</script>