<template>
  <section v-if="sliderLoad && forget" class="bought__section">
    <div class="container">
      <h2 class="cart__subtitle h2-title">Вы уже заказывали</h2>
      <div class="bought__wrapper flex">
        <li class="swiper mySwiper bought__swiper flex" style="overflow: hidden">
          <ul class="list-reset swiper-wrapper flex bought">
            <swiper :modules="modules" :pagination="true" :slides-per-view="2" :space-between="10">
              <template v-for="slide in forget">
                <swiper-slide>
                  <li class="swiper-slide card flex">
                    <ProductItem :item="slide"></ProductItem>
                  </li>
                </swiper-slide>
              </template>
            </swiper>
          </ul>
        </li>
      </div>

    </div>
  </section>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';
import ProductItem from './ProductItem'

export default {
  props: [

  ],
  data: () => ({
    modules: [Pagination],
    sliderLoad: false,
    forget: false
  }),
  computed: {

  },
  async created() {
    this.forget = await this.getforget();
    if (typeof this.forget == 'object' && this.forget.length > 0)
    this.sliderLoad = true;
  },
  methods: {
    async getforget() {
      await this.$store.dispatch('fetchForget')
      return this.$store.getters.forget;
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    ProductItem
  }
}
</script>