import axios from "axios";
export default {
    state: {
        review: false,
    },
    mutations: {
        review(state, review) {
            state.review = review;
        },
        refundDetail(state, refundDetail) {
            state.refundDetail = refundDetail.item;
        }
    },
    getters: {
        review: s => s.review,
    },
    actions: {
        async fetchReview({dispatch, commit}, data) {
            let response = await axios('/local/api/catalog/reviews/'+data.id).then(function (response) {
                let review = response.data; // читать тело ответа в формате JSON
                commit('review', review);
            }); // завершается с заголовками ответа

        }
    }
}
