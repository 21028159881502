import axios from 'axios'

export default {
    state: {
        section: [],
        products: [],
        sort: 'name_asc',
        search: '',
        searchResult: 0,
        product: false,
        sections: false,
        shop: [],
        current_shop: []
    },
    mutations: {
        setsection(state, data) {
            state.section[data.id] = data.section.items;
        },
        setproducts(state, data) {
            if (!state.products[data.section_id])
                state.products[data.section_id] = {
                    quick_filters: [],
                    items: [],
                    props: [],
                    paginations: []
                };
            if (data.showmore == true) {
                data.products['items'] = state.products[data.section_id]['items'].concat(data.products['items'])
            }
            state.products[data.section_id] = data.products;
        },
        setsort(state, data) {
            state.sort = data;
        },
        setsearch(state, data) {
            state.search = data;
        },
        setsearchResult(state, data) {
            state.searchResult = data;
        },
        product(state, data) {
            state.product = data.item;
        },
        sections(state, data) {
            state.sections = data.items;
        },
        shop(state, data) {
            state.shop = data.items;
            state.current_shop = data.current;
        }
    },
    getters: {
        section: (state) => (id) => {
            return state.section[id];
        },
        shop: (state) => {
            return state.shop;
        },
        current_shop: (state) => {
            return state.current_shop;
        },
        product: (state) => {
            return state.product;
        },
        sections: (state) => {
            return state.sections;
        },
        products: (state) => (section_id) => {
            return state.products[section_id];
        },
        searchResult: (state) => (id) => {
            return state.searchResult;
        }
    },
    actions: {
        async fetchSection({dispatch, commit}, data) {
            let query = '';
            if (data.sort) {
                let arSort = data.sort.split("_");
                query = '?by='+arSort[0]+'&order='+arSort[1];
            }
            if (data.search) {
                if (query)
                    query += '&';
                query += 'search='+data.search;
            }
            let response = await axios('/local/api/catalog/section/'+data.id+query).then(function (response) {
                let section = response.data; // читать тело ответа в формате JSON
                commit('setsection', {section:section, id:data.id});
                commit('setsearchResult', section.all);
            }); // завершается с заголовками ответа

        },
        async fetchProducts({dispatch, commit, state}, data) {
            let query = '';
            if (data.sort) {
                let arSort = data.sort.split("_");
                query = '?by='+arSort[0]+'&order='+arSort[1];
            }
            if (data.search) {
                if (query)
                    query += '&';
                else
                    query = "?";
                query += 'search='+data.search;
            }
            if (data.page) {
                if (query)
                    query += '&';
                else
                    query = "?";
                query += 'page='+data.page;
            }
            if (data && data.shop_id) {
                if (query)
                    query += '&';
                else
                    query = "?";
                query += 'shop_id='+data.shop_id;
            }
            /*if (data.sort == state.sort
                && data.search == state.search
                && state.products[data.section_id]
                )
                return;*/
            commit('setsort', data.sort);
            commit('setsearch', data.search);
            if (data.filter) {
                let response = await axios.post('/local/api/catalog/products/'+data.section_id+query,
                    {
                        filter: data.filter
                    }, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    ).then(function (response) {
                    let products = response.data; // читать тело ответа в формате JSON
                    commit('setproducts', {products:products, section_id:data.section_id, showmore:data.showmore});
                });
            } else {
                let response = await axios('/local/api/catalog/products/'+data.section_id+query).then(function (response) {
                    let products = response.data; // читать тело ответа в формате JSON
                    commit('setproducts', {products:products, section_id:data.section_id, showmore:data.showmore});
                });
            }
             // завершается с заголовками ответа

        },
        async fetchNew({dispatch, commit}, data) {
            let query = '';
            if (data.sort) {
                let arSort = data.sort.split("_");
                query = '?by='+arSort[0]+'&order='+arSort[1];
            }
            if (data.search) {
                if (query)
                    query += '&';
                else
                    query = "?";
                query += 'search='+data.search;
            }
            let response = await axios('/local/api/catalog/new'+query).then(function (response) {
                let section = response.data; // читать тело ответа в формате JSON
                commit('setsection', {section:section.section, id:'new'});
                for (let brand_id in section.products)
                {
                    commit('setproducts', {products:section.products[brand_id], section_id:'new', brand_id:brand_id});
                }
                commit('setsearchResult', section.all);
                commit('setsort', data.sort);
                commit('setsearch', data.search);
            }); // завершается с заголовками ответа

        },
        async fetchSale({dispatch, commit}, data) {
            debugger;
            let query = '';
            if (data.sort) {
                let arSort = data.sort.split("_");
                query = '?by='+arSort[0]+'&order='+arSort[1];
            }
            if (data.search) {
                if (query)
                    query += '&';
                else
                    query = "?";
                query += 'search='+data.search;
            }
            let response = await axios('/local/api/catalog/sale'+query).then(function (response) {
                let section = response.data; // читать тело ответа в формате JSON
                commit('setsection', {section:section.section, id:'sale'});
                for (let brand_id in section.products)
                {
                    commit('setproducts', {products:section.products[brand_id], section_id:'sale', brand_id:brand_id});
                }
                commit('setsearchResult', section.all);
                commit('setsort', data.sort);
                commit('setsearch', data.search);
            }); // завершается с заголовками ответа

        },
        async fetchProduct({dispatch, commit}, data) {

            let response = await axios('/local/api/catalog/detail/'+data.id).then(function (response) {
                let product = response.data; // читать тело ответа в формате JSON
                commit('product', product);
            }); // завершается с заголовками ответа

        },
        async fetchSections({dispatch, commit, state}, data) {
            let shop_id = 0; //state.current_shop.id;
            if (data && data.shop_id)
                shop_id = data.shop_id;
            let response = await axios('/local/api/catalog/sections/'+shop_id).then(function (response) {
                let sections = response.data; // читать тело ответа в формате JSON
                commit('sections', sections);
            }); // завершается с заголовками ответа
        },
        async fetchShop({dispatch, commit}) {
            let response = await axios('/local/api/catalog/shop').then(function (response) {
                let shop = response.data; // читать тело ответа в формате JSON
                commit('shop', shop);
            }); // завершается с заголовками ответа
        },
        clearProduct({dispatch, commit, state}) {
            state.product = false;
        },
        async setShop({dispatch, commit, state}, data) {
            axios.post( '/local/api/catalog/shop',
                {
                    id:data.id
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then(function(response){
                let shop = response.data; // читать тело ответа в формате JSON
                commit('shop', shop);
            })
                .catch(function(){

                });

        },
    }
}
