<template>
  <section v-if="orders" class="section">
    <div class="container">
      <div class="section__wrapper">
        <ul class="list-reset orders__list">
          <li v-for="item in orders" class="orders__item">
            <router-link :to="'/orderdetail/'+item.id">
            <div class="orders__info flex">
              <h4 class="orders__number">Заказ № {{ item.number }}</h4>
              <span class="orders__date">{{item.date_create}}</span>
            </div>
            <span class="orders__amount">{{item.quantity}} {{declOfNum(item.quantity, ['наименование', 'наименования','наименований'])}}</span>
            <div class="orders__price flex">
              <span class="orders__total">{{item.sum}} ₽</span>
              <span v-if="item.points" class="orders__balls balls-plus">{{item.points}} Б</span>
            </div>
            <span class="orders__status">{{ item.status_id }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  data: () =>  ({

  }),
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    orders() {
      return this.$store.getters.orders.items;
    }
  },
  methods: {
    async showOrders() {
      await this.$store.dispatch('fetchOrders')
    },
    declOfNum(n, text_forms) {
      n = Math.abs(n) % 100;
      let n1 = n % 10;
      if (n > 10 && n < 20) { return text_forms[2]; }
      if (n1 > 1 && n1 < 5) { return text_forms[1]; }
      if (n1 == 1) { return text_forms[0]; }
      return text_forms[2];
    }
  },
  created() {
    this.showOrders();
  },
  components: {VueDatePicker },
}
</script>

