<template>
  <section class="section">
    <div class="container">
      <p class="forgot__password--descr">
        К сожалению, такой страницы не существует
      </p>
      <router-link to="/" href="#" class="product__approve personal__form--btn">Вернуться на главную страницу</router-link>
    </div>
  </section>
</template>

<script>
import {ref, onUpdated, watch} from 'vue'
import { computed } from 'vue'
import {useGetToken} from "@/use/getToken";
import {useRouter} from "vue-router";

export default {
  mounted() {
    this.$store.dispatch('Loader');
  },
  components: {},
  methods: {},
}
</script>

