<template>
  <ul class="list-reset product__card--list">
    <li v-if="item.new" class="product__card--item product__new">
      NEW
    </li>
    <li class="product__card--item product__rating">
      {{ item.rate }} <svg width="9" height="9" viewBox="0 0 9 9" fill="none"
               xmlns="http://www.w3.org/2000/svg">
      <path
          d="M4.22791 0.807568C4.33543 0.576049 4.66457 0.576049 4.77209 0.807569L5.70461 2.81554C5.74832 2.90967 5.83757 2.97452 5.9406 2.987L8.13846 3.25338C8.39187 3.28409 8.49358 3.59713 8.30662 3.77093L6.68509 5.2783C6.60908 5.34896 6.57498 5.45388 6.59494 5.55573L7.02078 7.72833C7.06988 7.97883 6.8036 8.1723 6.58053 8.04819L4.64585 6.97183C4.55516 6.92137 4.44484 6.92137 4.35415 6.97183L2.41947 8.04819C2.1964 8.1723 1.93012 7.97883 1.97922 7.72833L2.40506 5.55573C2.42502 5.45389 2.39092 5.34896 2.31491 5.2783L0.693378 3.77093C0.506415 3.59713 0.608126 3.28409 0.86154 3.25338L3.0594 2.987C3.16243 2.97452 3.25168 2.90967 3.29539 2.81554L4.22791 0.807568Z"
          fill="black" />
    </svg>
    </li>
  </ul>
  <div  class="card__link flex">
    <router-link :to="'/detail/'+item.id" class="card__img new rating">
      <img :src="item.image">
    </router-link>
    <div class="card__content">
      <router-link :to="'/detail/'+item.id" class="card__subtitle">{{item.name}}</router-link>
      <p class="card__preview" v-if="item.preview">{{item.preview}}</p>
      <div class="card__inner">
        <div class="card__info flex">
          <div v-if="item.og" class="card__must flex">
            <span class="card__quantity">{{ item.og }}</span>
            <span class="card__parameter">OG</span>
          </div>
          <div v-if="item.volume" class="card__alch flex">
            <span class="card__quantity">{{ item.volume }}</span>
            <span class="card__parameter">VOL</span>
          </div>
        </div>
        <div v-if="quantity == 0" class="card__price flex"  @click="changeQuantity('plus')">
          <span class="card__cost">{{ item.price }} ₽</span>
          <span class="border"></span>
          <span class="card__volume">{{ item.ratio }} {{ item.unit }}</span>
        </div>
        <div v-if="quantity > 0" class="product__count--buttons flex cart__counts product__count--buttons__active">
          <button @click="changeQuantity('minus')" class="btn-reset product__count--button__minus">
          </button>
          <div class="count">
            <span class="quantity">{{ quantity }}</span>
            <span class="volume">{{ item.unit }}</span>
          </div>
          <span class="border"></span>
          <div class="summ">
            <span class="cost">{{ item.price/item.ratio*quantity }}</span>
            <span class="currency">₽</span>
          </div>
          <button @click="changeQuantity('plus')" class="btn-reset product__count--button__plus"></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    item: {},
    itembasket: false,
    itemorder: false
  },
  data: () => ({
    quantity: 0
  }),
  computed: {
    basket() {
      let basket_quantity = this.$store.getters.inBasket(this.$props.item.id);
      if (basket_quantity != this.quantity)
        this.quantity = basket_quantity;
      return basket_quantity;
    }
  },
  watch: {
    basket(val) {
      if (val != this.quantity)
        this.quantity = val;
    }
  },
  created() {

  },
  methods: {
    changeQuantity(type) {
      if (!this.$store.getters.isAuth)
        this.$router.push('/login');
      let act = '';
      let quantity = this.quantity;
      if (type == 'plus') {
        if (this.$props.item.quantity <= this.basket)
          return;
        if (quantity == 0)
          act = 'add';
        else
          act = 'update';
        quantity += this.$props.item.ratio;
      }
      else {
        if (quantity > this.$props.item.ratio) {
          act = 'update';
        }
        else if (quantity == this.$props.item.ratio) {
          act = 'delete';
        }
        if (act)
          quantity -= this.$props.item.ratio;
      }
      if (act == 'add')
        this.$store.dispatch({type:'addBasket', id: this.$props.item.id, quantity: quantity})
      else if (act == 'update')
        this.$store.dispatch({type:'updateBasket', id: this.$props.item.id, quantity: quantity})
      else if (act == 'delete')
        this.$store.dispatch({type:'deleteBasket', id: this.$props.item.id, quantity: quantity})
    },
    deleteItem() {
      this.$store.dispatch({type:'deleteBasket', id: this.$props.item.id, quantity: 0})
    }
  },
  components: {

  }
}
</script>