<template>
  <section class="section">
    <Shop></Shop>
    <div class="swiper__container">
      <div class="swiper mySwiper swiper__nav--wrapper">
        <ul class="list-reset swiper-wrapper swiper__nav--list flex">

          <li v-for="item in quick_filters" class="swiper-slide swiper__nav--item">
            <button @click="quickSearch($event, item.id)"
              class="btn-reset swiper__nav--btn">{{ item.name }}</button>
          </li>
        </ul>
      </div>
    </div>

    <div class="container">

      <button @click="showfilter=true" class="btn-reset novelty__filter flex"><svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                          xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_100_2715)">
          <path
              d="M0.833333 3.95855H3.11333C3.2922 4.61666 3.68264 5.19763 4.22444 5.61182C4.76623 6.02602 5.42927 6.25043 6.11125 6.25043C6.79323 6.25043 7.45627 6.02602 7.99806 5.61182C8.53986 5.19763 8.9303 4.61666 9.10917 3.95855H19.1667C19.3877 3.95855 19.5996 3.87075 19.7559 3.71447C19.9122 3.55819 20 3.34623 20 3.12522C20 2.9042 19.9122 2.69224 19.7559 2.53596C19.5996 2.37968 19.3877 2.29188 19.1667 2.29188H9.10917C8.9303 1.63377 8.53986 1.0528 7.99806 0.638607C7.45627 0.224409 6.79323 0 6.11125 0C5.42927 0 4.76623 0.224409 4.22444 0.638607C3.68264 1.0528 3.2922 1.63377 3.11333 2.29188H0.833333C0.61232 2.29188 0.400358 2.37968 0.244078 2.53596C0.0877974 2.69224 0 2.9042 0 3.12522C0 3.34623 0.0877974 3.55819 0.244078 3.71447C0.400358 3.87075 0.61232 3.95855 0.833333 3.95855ZM6.11083 1.66688C6.39926 1.66688 6.68122 1.75241 6.92104 1.91266C7.16086 2.0729 7.34778 2.30066 7.45816 2.56714C7.56854 2.83361 7.59742 3.12683 7.54115 3.40972C7.48488 3.69261 7.34598 3.95246 7.14203 4.15641C6.93808 4.36036 6.67823 4.49926 6.39534 4.55553C6.11245 4.6118 5.81923 4.58292 5.55275 4.47254C5.28628 4.36216 5.05852 4.17524 4.89827 3.93542C4.73803 3.6956 4.6525 3.41365 4.6525 3.12522C4.65294 2.73858 4.80673 2.3679 5.08012 2.09451C5.35352 1.82111 5.72419 1.66732 6.11083 1.66688Z"
              fill="black" />
          <path
              d="M19.1667 9.16574H16.8867C16.7081 8.50748 16.3178 7.9263 15.7761 7.51193C15.2343 7.09756 14.5712 6.87305 13.8892 6.87305C13.2071 6.87305 12.544 7.09756 12.0023 7.51193C11.4605 7.9263 11.0702 8.50748 10.8917 9.16574H0.833333C0.61232 9.16574 0.400358 9.25353 0.244078 9.40981C0.0877974 9.56609 0 9.77806 0 9.99907C0 10.2201 0.0877974 10.432 0.244078 10.5883C0.400358 10.7446 0.61232 10.8324 0.833333 10.8324H10.8917C11.0702 11.4907 11.4605 12.0718 12.0023 12.4862C12.544 12.9006 13.2071 13.1251 13.8892 13.1251C14.5712 13.1251 15.2343 12.9006 15.7761 12.4862C16.3178 12.0718 16.7081 11.4907 16.8867 10.8324H19.1667C19.3877 10.8324 19.5996 10.7446 19.7559 10.5883C19.9122 10.432 20 10.2201 20 9.99907C20 9.77806 19.9122 9.56609 19.7559 9.40981C19.5996 9.25353 19.3877 9.16574 19.1667 9.16574ZM13.8892 11.4574C13.6007 11.4574 13.3188 11.3719 13.079 11.2116C12.8391 11.0514 12.6522 10.8236 12.5418 10.5571C12.4315 10.2907 12.4026 9.99745 12.4589 9.71456C12.5151 9.43167 12.654 9.17182 12.858 8.96787C13.0619 8.76392 13.3218 8.62503 13.6047 8.56876C13.8875 8.51249 14.1808 8.54137 14.4472 8.65175C14.7137 8.76213 14.9415 8.94904 15.1017 9.18886C15.262 9.42869 15.3475 9.71064 15.3475 9.99907C15.3471 10.3857 15.1933 10.7564 14.9199 11.0298C14.6465 11.3032 14.2758 11.457 13.8892 11.4574Z"
              fill="black" />
          <path
              d="M19.1667 16.0419H9.10917C8.9303 15.3838 8.53986 14.8028 7.99806 14.3886C7.45627 13.9744 6.79323 13.75 6.11125 13.75C5.42927 13.75 4.76623 13.9744 4.22444 14.3886C3.68264 14.8028 3.2922 15.3838 3.11333 16.0419H0.833333C0.61232 16.0419 0.400358 16.1297 0.244078 16.286C0.0877974 16.4422 0 16.6542 0 16.8752C0 17.0962 0.0877974 17.3082 0.244078 17.4645C0.400358 17.6207 0.61232 17.7085 0.833333 17.7085H3.11333C3.2922 18.3666 3.68264 18.9476 4.22444 19.3618C4.76623 19.776 5.42927 20.0004 6.11125 20.0004C6.79323 20.0004 7.45627 19.776 7.99806 19.3618C8.53986 18.9476 8.9303 18.3666 9.10917 17.7085H19.1667C19.3877 17.7085 19.5996 17.6207 19.7559 17.4645C19.9122 17.3082 20 17.0962 20 16.8752C20 16.6542 19.9122 16.4422 19.7559 16.286C19.5996 16.1297 19.3877 16.0419 19.1667 16.0419ZM6.11083 18.3335C5.8224 18.3335 5.54045 18.248 5.30063 18.0878C5.0608 17.9275 4.87389 17.6998 4.76351 17.4333C4.65313 17.1668 4.62425 16.8736 4.68052 16.5907C4.73679 16.3078 4.87568 16.048 5.07964 15.844C5.28359 15.6401 5.54344 15.5012 5.82633 15.4449C6.10922 15.3886 6.40244 15.4175 6.66891 15.5279C6.93539 15.6383 7.16315 15.8252 7.32339 16.065C7.48364 16.3048 7.56917 16.5868 7.56917 16.8752C7.56851 17.2618 7.41465 17.6323 7.1413 17.9057C6.86795 18.179 6.4974 18.3329 6.11083 18.3335Z"
              fill="black" />
        </g>
        <defs>
          <clipPath id="clip0_100_2715">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>Фильтры</button>

      <div class="section__content">
        <div class="filters filters--active" v-if="showfilter">
          <div class="section__header">
            <div class="header__wrapper flex">
              <a @click="showfilter=false" href="#" class="header__link">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12.9053 15.2803C12.6124 15.5732 12.1376 15.5732 11.8447 15.2803L7.09467 10.5303C6.80178 10.2374 6.80178 9.76256 7.09467 9.46967L11.8447 4.71967C12.1376 4.42678 12.6124 4.42678 12.9053 4.71967C13.1982 5.01256 13.1982 5.48744 12.9053 5.78033L8.68566 10L12.9053 14.2197C13.1982 14.5126 13.1982 14.9874 12.9053 15.2803Z"
                        fill="black" />
                </svg>

              </a>
              <h2 class="header__title h2-title">Фильтры</h2>
              <button @click="resetSearch" class="btn-reset filters__reset">сбросить</button>
            </div>
          </div>

          <h4 class="filters__type">Сортировка</h4>
          <form class="filters__form">
            <label class="filters__label">
              <input v-model="sort" type="radio" value="name_asc" class="filters__input">
              <span class="filters__name">по популярности</span>
            </label>
            <label class="filters__label">
              <input v-model="sort" type="radio" value="id_desc" class="filters__input">
              <span class="filters__name">сначала новые</span>
            </label>
            <label class="filters__label">
              <input v-model="sort" type="radio" value="price_asc" class="filters__input">
              <span class="filters__name">сначала дешевые</span>
            </label>
            <label class="filters__label">
              <input v-model="sort" type="radio" value="price_desc" class="filters__input">
              <span class="filters__name">сначала дорогие</span>
            </label>
          </form>

          <template v-for="item in props">
            <div class="filters__head">
              <h4 class="filters__type">{{ item.name }}</h4>
              <a v-if="item.values_count >= 7 && filter_show[item.id] != 'all'" @click="filter_show[item.id] = 'all'" href="#" class="filters__link">Все</a>
            </div>
            <form class="filters__form">
              <template v-for="(val, key, index) in item.values">
                <label class="filters__label" v-if="index < 7 || filter_show[item.id] == 'all'">
                  <input v-if="item.type=='radio'" v-model="price_filter[item.id]" :value="val.id" :type="item.type" class="filters__input">
                  <input v-if="item.type=='checkbox'" v-model="filter[item.id+'_'+val.id]" :value="val.id" :type="item.type" class="filters__input">
                  <span class="filters__name">{{ val.name }} </span>
                </label>
              </template>
            </form>
          </template>

          <a @click="doSearch(1)" href="#" class="personal__form--btn filters__show">Показать</a>
        </div>
        <div v-if="products" class="section__item section__item--active" data-target="russian">
          <div class="swiper mySwiper section__swiper--products swiper-overflow-hidden">
            <ul class="list-reset swiper-wrapper">
              <li class="swiper-slide section__swiper--item">
                <Elements
                          :id="$route.params.id"
                          :sort="sort"
                          :filter="search"
                ></Elements>
                <div v-if="pagination && pagination.NavPageCount > pagination.NavPageNomer" class="button-center flex">
                  <button @click="showMore(pagination.NavPageNomer+1)" class="btn-reset novelty__showmore">Показать ещё</button>
                </div>
                <div v-if="pagination && pagination.NavPageCount > 1" class="section__pagination flex">
                  <button @click="setPrevPage(pagination.NavPageNomer)" class="btn-reset section__prev"><svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                               xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M12.9053 15.2803C12.6124 15.5732 12.1376 15.5732 11.8447 15.2803L7.09467 10.5303C6.80178 10.2374 6.80178 9.76256 7.09467 9.46967L11.8447 4.71967C12.1376 4.42678 12.6124 4.42678 12.9053 4.71967C13.1982 5.01256 13.1982 5.48744 12.9053 5.78033L8.68566 10L12.9053 14.2197C13.1982 14.5126 13.1982 14.9874 12.9053 15.2803Z"
                          fill="black" />
                  </svg>
                  </button>
                  <ul class="list-reset section__pagination--list flex">
                    <li v-for="item in pagination.pages" class="section__pagination--item" >
                      <button @click="setPage(item)" class="btn-reset section__pagination--count" :class="{'section__pagination--count__active':item==pagination.NavPageNomer}">{{ item }}</button>
                    </li>
                  </ul>

                  <button @click="setNextPage(pagination.NavPageNomer)" class="btn-reset section__next"><svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                               xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M7.09467 4.71967C7.38756 4.42678 7.86244 4.42678 8.15533 4.71967L12.9053 9.46967C13.1982 9.76256 13.1982 10.2374 12.9053 10.5303L8.15533 15.2803C7.86244 15.5732 7.38756 15.5732 7.09467 15.2803C6.80178 14.9874 6.80178 14.5126 7.09467 14.2197L11.3143 10L7.09467 5.78033C6.80178 5.48744 6.80178 5.01256 7.09467 4.71967Z"
                          fill="black" />
                  </svg>
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Elements from '../components/app/Elements'
import Shop from '../components/app/Shop'

export default {
  data: () => ({
   // id:this.$props.id
    modalOverlay: false,
    targetModal: false,
    sort: 'name_asc',
    search: '',
    searching:false,
    showfilter: false,
    filter: [],
    price_filter: [],
    filter_show: []
  }),
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    products() {
      if (typeof this.$store.getters.products(this.$route.params.id) == 'undefined')
        return [];
      return this.$store.getters.products(this.$route.params.id)['items'];
    },
    props() {
      if (typeof this.$store.getters.products(this.$route.params.id) == 'undefined')
        return [];
      return this.$store.getters.products(this.$route.params.id)['props'];
    },
    pagination() {
      if (typeof this.$store.getters.products(this.$route.params.id) == 'undefined')
        return [];
      return this.$store.getters.products(this.$route.params.id)['pagination'];
    },
    quick_filters() {
      if (typeof this.$store.getters.products(this.$route.params.id) == 'undefined')
        return [];
      return this.$store.getters.products(this.$route.params.id)['quick_filters'];
    },
    searchResult() {
      return this.$store.getters.searchResult();
    }
  },
  async created() {
    await this.$store.dispatch({type:'fetchProducts', section_id:this.$route.params.id})
  },
  components: {Elements, Shop},
  methods: {
    openModal() {
      debugger;
      let body = $('body');


      body.addClass('fixed');
      this.modalOverlay='modal--open';
      this.targetModal='modal__body--open';

      /*if (itemToRemove !== false) {
        let confirmBtn = targetModal.find('.modal__confirm-btn');

        confirmBtn.on('click', function(e) {
          e.preventDefault();

          itemToRemove.remove();
          closeModals();
        });
      }*/
    },
    closeModals() {
      let body = $('body');
      body.removeClass('fixed');
      this.modalOverlay='';
      this.targetModal='';
    },
    resetSearch() {
      this.filter = [];
      this.price_filter = [];
      this.doSearch(1);
    },
    async doSearch(page=1) {
      let value, filter = {};
      for (let i in this.filter) {
        if (this.filter[i]) {
          value = i.split('_');
          if (typeof filter['PROPERTY_'+value[0]] == 'undefined')
            filter['PROPERTY_'+value[0]] = [];
          filter['PROPERTY_'+value[0]].push(value[1])
        }
      }
      for (let i in this.price_filter) {
        filter['PROPERTY_'+i] = this.price_filter[i];
      }

      this.$store.dispatch({type:'fetchProducts', section_id:this.$route.params.id, sort: this.sort, filter: filter, page:page});
      this.showfilter = false;
    },
    quickSearch(e, id) {
      if (e.target.classList.contains('swiper__nav--btn__active')) {
          e.target.classList.remove('swiper__nav--btn__active');

          this.filter = {};
      } else {
          e.target.classList.add('swiper__nav--btn__active');

          let filter = this.quick_filters[id].filter;
          this.filter = {};
          this.filter[filter[0]+'_'+filter[1]] = true;
      }

      this.doSearch(1);
    },
    setPage(page) {
      this.doSearch(page);
      //this.$store.dispatch({type:'fetchProducts', section_id:this.$route.params.id, sort: this.sort, page:page});
    },
    setPrevPage(page) {
      if (page > 1)
        this.doSearch((page-1));
        //this.$store.dispatch({type:'fetchProducts', section_id:this.$route.params.id, sort: this.sort, page:page-1});
    },
    setNextPage(page) {
      if (page < this.pagination.NavPageCount)
        this.doSearch((page+1));
        //his.$store.dispatch({type:'fetchProducts', section_id:this.$route.params.id, sort: this.sort, page:page+1});
    },
    showMore(page) {
      this.doSearch(page);
      //this.$store.dispatch({type:'fetchProducts', showmore:true, section_id:this.$route.params.id, sort: this.sort, page:page});
    }
  },
}
</script>

