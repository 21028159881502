<template>
  <section class="section">
    <div class="container">
      <div class="section__wrapper">
        <p class="forgot__password--descr">На указанный e-mail<br> будет выслан код для сброса пароля.</p>
        <div class="info-block info-block--alert" v-show="error.length>0">
          <h3>Внимание</h3>
          <p v-for="item in error">{{item}}</p>
        </div>
        <form @submit.prevent="request" method="POST"
              class="registration__form personal-form forgot__password--form">
          <label>
            <span class="personal__form--descr">E-mail <span>*</span></span>
            <input v-model="email" required type="email" placeholder="test@mail.ru" class="personal-input forgot__password--input">
          </label>
          <button type="submit" class="btn-reset personal__form--btn">Отправить</button>
        </form>
        </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  props: [
  ],
  data: () => ({
    email: '',
    error: []
  }),
  created() {

  },
  mounted() {

    this.$store.dispatch('Loader');
  },
  beforeRouteLeave(to, from) {

    // called when the route that renders this component is about to be navigated away from.
    // As with `beforeRouteUpdate`, it has access to `this` component instance.
  },
  methods: {
    request() {
      this.error = [];

      if (!this.email)
        this.error.push('Введите ваш Email');
      if (this.error.length > 0) {
        $(window).scrollTop($(".info-block--alert").offset().top);
        return;
      }

      let formData = new FormData();
      formData.append('email', this.email);
      let _this = this;
      axios.post( '/local/api/password/request',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function(res){
        console.log(res)
        console.log('SUCCESS!!');
        if (res.data.error) {
          _this.error = res.data.error;
          $(window).scrollTop($(".info-block--alert").offset().top);
        }
        if (res.data.status == 200) {
          _this.$router.push({path: '/new-password', query: { email: _this.email }});
        }
      })
          .catch(function(){
            console.log('FAILURE!!');
          });
    },
  },
  components: {

  }
}
</script>

