<template>
  <template v-if="review">
    <h2 class="product__subtitle h2-title">Отзывы<span>{{review.count}}</span></h2>
    <ul class="list-reset product__list--stars">
      <li v-for="i in stars" class="product__item">
        <button @click="setstar(i)" class="btn-reset product__item--star">
          <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.593 0.952964C17.9514 0.181232 19.0486 0.181231 19.407 0.952963L24.0736 11.0015C24.2193 11.3153 24.5168 11.5314 24.8602 11.573L35.859 12.9061C36.7037 13.0085 37.0428 14.0519 36.4195 14.6312L28.3049 22.1746C28.0515 22.4101 27.9379 22.7599 28.0044 23.0994L30.1354 33.9718C30.2991 34.8068 29.4115 35.4517 28.6679 35.038L18.9862 29.6515C18.6839 29.4833 18.3161 29.4833 18.0138 29.6515L8.33208 35.038C7.58852 35.4517 6.70091 34.8068 6.86457 33.9718L8.9956 23.0994C9.06214 22.7599 8.9485 22.4101 8.69513 22.1746L0.580454 14.6312C-0.0427566 14.0519 0.296277 13.0085 1.14099 12.9061L12.1398 11.573C12.4832 11.5314 12.7807 11.3153 12.9264 11.0015L17.593 0.952964Z" :fill="star >= i?'#FBCD0B':'#E2E2E2'"></path>
          </svg>

        </button>
      </li>
    </ul>
    <h4 class="product__opinion">Оставьте ваш отзыв</h4>
    <form action="#" method="POST" class="product__form" @submit.prevent="send">
      <input type="hidden" v-model="star" />
      <label>
        <input v-model="text" type="text" placeholder="Начните вводить текст" class="product__input">
      </label>
      <div class="info-block info-block--alert" v-show="error.length>0">
        <h3>Внимание</h3>
        <p v-for="item in error">{{item}}</p>
      </div>
      <div v-show="success" class="info-block info-block--success">
        <p>{{ success }}</p>
      </div>
      <button type="submit" class="btn-reset product__submit">Отправить</button>
    </form>
    <div class="product__comments">
      <div v-for="item in review.items" class="product__comment">
        <div class="product__commentator">
          <span class="product__commentator--name">{{ item.name }}</span>
          <span class="product__commentator--date">{{ item.date }}</span>
        </div>
        <ul class="list-reset product__comment--stars">
          <li v-for="i in [1,2,3,4,5]" class="product__comment">
                        <span class="product__comment--star"><svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.72791 0.585889C9.83543 0.35437 10.1646 0.354369 10.2721 0.585889L12.7628 5.94916C12.8065 6.04329 12.8958 6.10813 12.9988 6.12062L18.8693 6.83211C19.1227 6.86282 19.2244 7.17585 19.0374 7.34965L14.7063 11.3758C14.6303 11.4465 14.5962 11.5514 14.6162 11.6533L15.7536 17.4562C15.8027 17.7067 15.5364 17.9002 15.3133 17.7761L10.1459 14.9011C10.0552 14.8507 9.94484 14.8507 9.85415 14.9011L4.68665 17.7761C4.46358 17.9002 4.1973 17.7067 4.2464 17.4562L5.3838 11.6533C5.40377 11.5514 5.36967 11.4465 5.29366 11.3758L0.962568 7.34965C0.775605 7.17585 0.877316 6.86282 1.13073 6.83211L7.00118 6.12062C7.10421 6.10813 7.19346 6.04329 7.23718 5.94916L9.72791 0.585889Z" :fill="item.rate>=i?'#FBCD0B':'#E2E2E2'"></path>
                          </svg>
                        </span>
          </li>
        </ul>
        <span class="product__review">{{ item.text }}</span>
      </div>
    </div>
  </template>
</template>
<script>
import axios from "axios";

export default {
  props: [

  ],
  data: () => ({
   // review: []
    star: 0,
    text: '',
    stars: [
      1,2,3,4,5
    ],
    error: false,
    success: false,
  }),
  computed: {
    review() {
      return this.$store.getters.review;
    }
  },
  async created() {
    this.review = await this.getreview();
  },
  methods: {
    async getreview() {
        this.$store.dispatch({type:'fetchReview', id:this.$route.params.id})
    },
    setstar(i) {
      this.star=i;
    },
    send() {
      this.error = [];
      this.success = false;

      if (!this.text)
        this.error.push('Напишите отзыв');
      if (!this.star)
        this.error.push('Укажите оценку');

      if (this.error.length > 0) {
        $(window).scrollTop($(".info-block--alert").offset().top);
        return;
      }

      let formData = new FormData();
      formData.append('rate', this.star);
      formData.append('text', this.text);

      let _this = this;
      axios.post( '/local/api/catalog/reviews/'+this.$route.params.id,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function(res){
        if (res.data.error) {
          _this.error = res.data.error;
          $(window).scrollTop($(".info-block--alert").offset().top);
        }
        if (res.data.status == 200) {
          _this.star = 0;
          _this.text = '';
          _this.success = res.data.text;
        }
      })
          .catch(function(){
            console.log('FAILURE!!');
          });


    },
    setshop(id) {
      this.$store.dispatch({type: 'setShop', id: id});
      if (this.$route.name == 'catalog')
        this.$store.dispatch('fetchSections')
      else
        this.$store.dispatch({type:'fetchProducts', section_id:this.$route.params.id});
      this.$store.dispatch('fetchFlyBasket')
    },
    clearBasket() {
      this.setshop(this.newid);
      this.newid = false;
      this.showClearBasket = false;
    },
    resetShop() {
      this.modelShop = this.$store.getters.current_shop.id;
      this.newid = false;
      this.showClearBasket = false;
    }
  },
  watch: {
    modelShop(id) {
      if (this.current_shop && id == this.current_shop.id)
        return;
      if (true || this.$store.getters.fly_basket) {
        this.newid = id;
        this.showPopup = false;
        this.showClearBasket = true;
      }
      else {
        this.setshop(id);
        this.showPopup = false;
      }
    }
  },
  components: {

  }
}
</script>