import axios from 'axios'
import Vuex from 'vuex'
import slider from './store/slider'
import homeSale from './store/homeSale'
import homeNew from './store/homeNew'
import homeAbout from './store/homeAbout'
import section from './store/section'
import basket from './store/basket'
import order from './store/order'
import personal from './store/personal'
import company from './store/company'
import review from './store/review'
import page from './store/page'

export default new Vuex.Store({
    state: {
        error: null,
        isAuth: false,
        login: '',
        user: '',
        loader: false,
        uuid: '',
        showBarCode: false,
        config: {
            headers:{
                token: localStorage.getItem('user-token')
            }
        }
    },
    mutations: {
        Auth(state, data) {
            state.isAuth = true;
            localStorage.setItem('user-token', data.token);
            state.login = data.login
            state.user = data.user
        },
        User(state, user) {
            state.user = user
            state.user.PERSONAL_BIRTHDAY = user.PERSONAL_BIRTHDAY;
            state.user.EMAIL = user.EMAIL;
            state.user.PERSONAL_PHONE = user.PERSONAL_PHONE;
            state.user.NAME = user.NAME;
        },
        CurrentShop(state, current_shop) {
            state.user.CURRENT_SHOP = current_shop;
        },
        Loader(state, data) {
            state.loader = true;
        },
        unLoader(state, data) {
            state.loader = false;
        },
        setError(state, error) {
            state.error = error
        },
        clearError(state) {
            state.error = null
        },
        Uuid(state, data) {
            state.uuid = data
        },
        showBarCode(state, data) {
            debugger;
            state.showBarCode = data
        },
        Logout(state, data) {
            state.isAuth = false;
            localStorage.setItem('user-token', '');
            state.basket = {
                fly_basket: {},
                basket_items: {},
                basket: [],
                forget: false
            };
            //state.login = data.login
        },
    },
    actions: {
        async Auth({commit, dispatch}, data) {
            commit('Auth', data);
        },
        User({commit, dispatch}, data) {
            commit('User', data);
        },
        Loader({commit, dispatch}, data) {
            commit('Loader');
        },
        unLoader({commit, dispatch}, data) {
            commit('unLoader');
        },
        Uuid({commit, dispatch}, data) {
            commit('Uuid', data);
        },
        async Logout({commit, dispatch}, data) {
            commit('Logout');
        },
        showBarCode({commit, dispatch}) {
            commit('showBarCode', true);
        },
    },
    getters: {
        error: s => s.error,
        isAuth: s => s.isAuth,
        login: s => s.login,
        user: s => s.user,
        loader: s => s.loader,
        uuid: s => s.uuid,
        config: s => s.config,
        showBarCode: s => s.showBarCode
    },
    modules: {
        slider,
        homeSale,
        homeNew,
        homeAbout,
        section,
        basket,
        order,
        personal,
        company,
        review,
        page
    }
})
