<template>

</template>

<script>
import axios from "axios";
export default {
  name: "OrderSuccess",
  created() {
    //if (this.$store.getters.orderInfo(this.$route.params.id) == undefined)
    this.getInfo();
  },
  methods: {
    async getInfo() {
      let _this = this;
      axios.post( '/local/api/order/lastorder',
          [],
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function(res){
        if (res.data.error) {
          _this.error = res.data.error;
          $(window).scrollTop($(".info-block--alert").offset().top);
        }
        if (res.data.status == 200) {
          let orderInfo = {
            id: res.data.item.id,
            points: res.data.item.property.POINTS,
            fio: res.data.item.property.NAME,
            phone: res.data.item.property.PHONE,
            //comment: res.data.item.property.COMMENT,
            canceled: 'N',
            shop: res.data.shop
          }
          _this.$store.commit('orderInfo', orderInfo);
          _this.$store.commit('fly_basket', {item:{}, items:{}});
          _this.$router.push('/order-complete/'+res.data.item.id);
        }
      })

    },
  }
}


</script>