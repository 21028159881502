<template>
  <section class="section personal">
    <div class="container">
      <div class="section__wrapper" style="padding-bottom:0px">
        <div class="personal__info">
          <h5 class="personal__name">{{ user.NAME }}</h5>
          <span class="personal__tel">{{user.PERSONAL_PHONE}}</span>
        </div>
      </div>
    </div>
    <section class="hero">
      <div class="container">
        <div class="code-wrapper" v-if="!showBarcode">
          <img @click="showBarcode = true" style="display:block;" class="code" src="img/code-tall.png" alt="Штрихкод">
        </div>

        <div v-if="isAuth" class="popup__container">
          <div class="hero__popup" :class="{'hero__popup--active':popupBarCode}" v-if="popupBarCode">
            <div class="hero__popup--card">
              <div class="hero__card--text flex">
                <button @click="popupBarCode = false" class="btn-reset hero__cancel"><svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                                                          xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M6.71967 6.71967C7.01256 6.42678 7.48744 6.42678 7.78033 6.71967L10 8.93934L12.2197 6.71967C12.5126 6.42678 12.9874 6.42678 13.2803 6.71967C13.5732 7.01256 13.5732 7.48744 13.2803 7.78033L11.0607 10L13.2803 12.2197C13.5732 12.5126 13.5732 12.9874 13.2803 13.2803C12.9874 13.5732 12.5126 13.5732 12.2197 13.2803L10 11.0607L7.78033 13.2803C7.48744 13.5732 7.01256 13.5732 6.71967 13.2803C6.42678 12.9874 6.42678 12.5126 6.71967 12.2197L8.93934 10L6.71967 7.78033C6.42678 7.48744 6.42678 7.01256 6.71967 6.71967Z"
                        fill="black" />
                </svg>
                </button>
                <div class="hero__card--popup__information flex">
                  <span class="hero__card--type">Бонусная карта</span>
                  <span class="hero__card--bonus">{{ user.UF_POINTS }} {{declOfNum(user.UF_POINTS, ['балл','балла','баллов'])}}</span>
                </div>
              </div>
              <div class="hero__card--code">
                <barcode :value="user.UF_BARCODE" format="pharmacode" :options="{width: 4, height: '226'}">
                  Show this if the rendering fails.
                </barcode>
              </div>
            </div>
          </div>
        </div>

        <div v-if="isAuth && showBarcode" class="hero__card flex">
          <div class="hero__card--content">
            <div @click="popupBarCode=true" class="hero__card--logo">
              <img src="img/icon-card-logo.svg" alt="Логотип ХМЕЛЪ и СОЛОДЪ">
            </div>
            <div class="hero__card--info">
              <div class="hero__card--wrapp">
                <span class="hero__card--type">Бонусная карта:</span>
                <span class="hero__card--bonus">{{ user.UF_POINTS }} {{declOfNum(user.UF_POINTS, ['балл','балла','баллов'])}}</span>
                <button @click="showBarcode=false" class="btn-reset hero__card--close">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 1L1 11" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                    <path d="M1 1L11 11" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                  </svg>
                </button>
              </div>
              <button @click="popupBarCode=true" class="btn-reset hero__card--trigger">
                <barcode :value="user.UF_BARCODE" :options="{width:'6', displayValue:false}">
                  Show this if the rendering fails.
                </barcode>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="container">
      <div class="section__wrapper">
        <ul class="list-reset address__list">
          <li class="address__item">
            <router-link to="/orders" href="#" class="personal__history flex personal-trigger">
              История заказов и баллов
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M7.09467 4.71967C7.38756 4.42678 7.86244 4.42678 8.15533 4.71967L12.9053 9.46967C13.1982 9.76256 13.1982 10.2374 12.9053 10.5303L8.15533 15.2803C7.86244 15.5732 7.38756 15.5732 7.09467 15.2803C6.80178 14.9874 6.80178 14.5126 7.09467 14.2197L11.3143 10L7.09467 5.78033C6.80178 5.48744 6.80178 5.01256 7.09467 4.71967Z"
                      fill="black" />
              </svg>
            </router-link>
          </li>
          <li class="address__item">
            <router-link to="/shop" href="addresses.html" class="address__link">
                Адреса
            </router-link>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7.09467 4.71967C7.38756 4.42678 7.86244 4.42678 8.15533 4.71967L12.9053 9.46967C13.1982 9.76256 13.1982 10.2374 12.9053 10.5303L8.15533 15.2803C7.86244 15.5732 7.38756 15.5732 7.09467 15.2803C6.80178 14.9874 6.80178 14.5126 7.09467 14.2197L11.3143 10L7.09467 5.78033C6.80178 5.48744 6.80178 5.01256 7.09467 4.71967Z"
                    fill="black" />
            </svg>
          </li>
          <li class="address__item">
            <router-link to="/page/about" href="#" class="address__link">
              О нас
            </router-link>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7.09467 4.71967C7.38756 4.42678 7.86244 4.42678 8.15533 4.71967L12.9053 9.46967C13.1982 9.76256 13.1982 10.2374 12.9053 10.5303L8.15533 15.2803C7.86244 15.5732 7.38756 15.5732 7.09467 15.2803C6.80178 14.9874 6.80178 14.5126 7.09467 14.2197L11.3143 10L7.09467 5.78033C6.80178 5.48744 6.80178 5.01256 7.09467 4.71967Z"
                    fill="black" />
            </svg>
          </li>
          <li class="address__item">
            <router-link to="/page/loyalty" href="#" class="address__link">
              Система лояльности
            </router-link>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.09467 4.71967C7.38756 4.42678 7.86244 4.42678 8.15533 4.71967L12.9053 9.46967C13.1982 9.76256 13.1982 10.2374 12.9053 10.5303L8.15533 15.2803C7.86244 15.5732 7.38756 15.5732 7.09467 15.2803C6.80178 14.9874 6.80178 14.5126 7.09467 14.2197L11.3143 10L7.09467 5.78033C6.80178 5.48744 6.80178 5.01256 7.09467 4.71967Z"
                  fill="black" />
          </svg>
          </li>
          <li class="address__item">
            <router-link to="/page/delivery" href="#" class="address__link">
              Оплата и доставка
            </router-link>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7.09467 4.71967C7.38756 4.42678 7.86244 4.42678 8.15533 4.71967L12.9053 9.46967C13.1982 9.76256 13.1982 10.2374 12.9053 10.5303L8.15533 15.2803C7.86244 15.5732 7.38756 15.5732 7.09467 15.2803C6.80178 14.9874 6.80178 14.5126 7.09467 14.2197L11.3143 10L7.09467 5.78033C6.80178 5.48744 6.80178 5.01256 7.09467 4.71967Z"
                    fill="black" />
            </svg>
          </li>
        </ul>
        <div class="personal__accordion">
          <button @click="profile = !profile" class="btn-reset accordion__trigger personal-trigger" :class="{'active': profile}">Мои данные</button>
          <div v-if="profile" class="accordion__panel">
            <Profile></Profile>
          </div>

          <button @click="password = !password" class="btn-reset accordion__trigger personal-trigger" :class="{'active': password}">Изменить пароль</button>
          <div v-if="password" class="accordion__panel">
            <ChangePassword></ChangePassword>
          </div>
        </div>

        <a @click="logout" href="#" class="personal__history flex personal-trigger">
          Выйти из аккаунта
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M11.2512 4.5L4.75116 4.5C4.0608 4.5 3.50116 5.05964 3.50116 5.75L3.50116 14.25C3.50116 14.9404 4.0608 15.5 4.75116 15.5H11.2512C11.6654 15.5 12.0012 15.8358 12.0012 16.25C12.0012 16.6642 11.6654 17 11.2512 17H4.75116C3.23238 17 2.00116 15.7688 2.00116 14.25L2.00116 5.75C2.00116 4.23122 3.23238 3 4.75116 3L11.2512 3C11.6654 3 12.0012 3.33579 12.0012 3.75C12.0012 4.16421 11.6654 4.5 11.2512 4.5ZM10.0318 6.71967C10.3247 7.01256 10.3247 7.48744 10.0318 7.78033L8.56213 9.25L17.249 9.25C17.6632 9.25 17.999 9.58578 17.999 10C17.999 10.4142 17.6632 10.75 17.249 10.75L8.56212 10.75L10.0318 12.2197C10.3247 12.5126 10.3247 12.9874 10.0318 13.2803C9.7389 13.5732 9.26403 13.5732 8.97114 13.2803L6.22113 10.5303C6.08048 10.3897 6.00146 10.1989 6.00146 10C6.00146 9.80109 6.08048 9.61032 6.22113 9.46967L8.97113 6.71967C9.26403 6.42678 9.7389 6.42678 10.0318 6.71967Z"
                  fill="black" />
          </svg>
        </a>

        <a @click="showDeleteAccountPopup = true" href="#" class="personal-trigger personal-trigger--delete">
          Удалить аккаунт
        </a>
      </div>
    </div>
  </section>

    <div
      class="popup__container"
      :class="showDeleteAccountPopup || showDeleteAccountSuccess ? 'popup__container--open' : ''"
    >
      <div v-if="showDeleteAccountPopup" class="change__location--popup change__location--popup__active" @touchstart="stoppag($event)">
        <div class="location__notice">
          <button class="btn-reset location__cancel" @click="showDeleteAccountPopup">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M6.71967 6.71967C7.01256 6.42678 7.48744 6.42678 7.78033 6.71967L10 8.93934L12.2197 6.71967C12.5126 6.42678 12.9874 6.42678 13.2803 6.71967C13.5732 7.01256 13.5732 7.48744 13.2803 7.78033L11.0607 10L13.2803 12.2197C13.5732 12.5126 13.5732 12.9874 13.2803 13.2803C12.9874 13.5732 12.5126 13.5732 12.2197 13.2803L10 11.0607L7.78033 13.2803C7.48744 13.5732 7.01256 13.5732 6.71967 13.2803C6.42678 12.9874 6.42678 12.5126 6.71967 12.2197L8.93934 10L6.71967 7.78033C6.42678 7.48744 6.42678 7.01256 6.71967 6.71967Z"
                    fill="black" />
            </svg>
          </button>
          <h5 class="change__location--subtitle">Вы действительно хотите удалить аккаунт?</h5>
          <div class="change__location--btns">
            <button @click="showDeleteAccountPopup = false" class="btn-reset change__location--btn__cancel">Отменить</button>
            <button @click="deleteAccount()" class="btn-reset change__location--btn__approve">Удалить аккаунт</button>
          </div>
        </div>
      </div>

      <div v-if="showDeleteAccountSuccess" class="change__location--popup change__location--popup__active" @touchstart="stoppag($event)">
        <div class="location__notice">
          <button class="btn-reset location__cancel" @click="showDeleteAccountSuccess = false">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M6.71967 6.71967C7.01256 6.42678 7.48744 6.42678 7.78033 6.71967L10 8.93934L12.2197 6.71967C12.5126 6.42678 12.9874 6.42678 13.2803 6.71967C13.5732 7.01256 13.5732 7.48744 13.2803 7.78033L11.0607 10L13.2803 12.2197C13.5732 12.5126 13.5732 12.9874 13.2803 13.2803C12.9874 13.5732 12.5126 13.5732 12.2197 13.2803L10 11.0607L7.78033 13.2803C7.48744 13.5732 7.01256 13.5732 6.71967 13.2803C6.42678 12.9874 6.42678 12.5126 6.71967 12.2197L8.93934 10L6.71967 7.78033C6.42678 7.48744 6.42678 7.01256 6.71967 6.71967Z"
                    fill="black" />
            </svg>
          </button>
          <h5 class="change__location--subtitle">Запрос на удаление аккаунта был передан модераторам</h5>
          <div class="change__location--btns">
              <button @click="showDeleteAccountSuccess = false" class="btn-reset change__location--btn__cancel">ОК</button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";
import VueBarcode from "@chenfengyuan/vue-barcode";
import Profile from "./Profile";
import ChangePassword from "./ChangePassword";

export default {
  props: [

  ],
  data: () => ({
    popupBarCode: false,
    showBarcode: true,
    showDeleteAccountPopup: false,
    showDeleteAccountSuccess: false,
    profile: false,
    password: false
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isAuth() {
      return this.$store.getters.isAuth;
    },
    showBarCode() {
      debugger;
      return this.$store.getters.showBarCode;
    },
  },
  created() {

  },
  mounted() {
    this.$store.dispatch('Loader');
  },
  methods: {
    stoppag: function(e) {
      e.stopPropagation();
    },
    async logout() {

      let device_uuid = this.$store.getters.uuid;
      const { data } = await axios.post('https://hmel.alm.su/local/api/logout', {
        act: 'logout'
      });
      if (data.is_auth === false) {
        this.$store.dispatch('Logout')
        this.$router.push('/login');
      }
      else
        console.log(data)
    },
    declOfNum(n, text_forms) {
      n = Math.abs(n) % 100;
      let n1 = n % 10;
      if (n > 10 && n < 20) { return text_forms[2]; }
      if (n1 > 1 && n1 < 5) { return text_forms[1]; }
      if (n1 == 1) { return text_forms[0]; }
      return text_forms[2];
    },
    deleteAccount() {
      this.showDeleteAccountPopup = false;

      let formData = new FormData();
      let _this = this;
      axios.post( '/local/api/personal/profile/delete',
          formData,
          {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          }
      ).then(function(res){
          console.log(res)
          console.log('SUCCESS!!');
          if (res.data.error) {
              // _this.error = res.data.error;
          }
          if (res.data.status == 200) {
              // _this.success = res.data.text;
              _this.showDeleteAccountSuccess = true;
          }
      })
      // .catch(function(){
      //     console.log('FAILURE!!');
      // });
    },
  },
  components: {
    ChangePassword,
    Profile,
    'barcode': VueBarcode
  }
}
</script>

